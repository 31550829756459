@import './var';

.user-delete-form-wrapper {
    padding: .5rem 4rem;

    @media screen and (max-width: 540px) {
        padding: .5rem 1.5rem;
    }

    .user-delete-form {
        background: #fff;
        padding: 1rem 2rem;
        border-radius: 1rem;
        // box-shadow: 0 0 .4rem .1rem #00000034;
        font-size: 0.8rem;
        width: 100%;
        max-width: 37rem;
        margin: auto;
        display: grid;
        gap: .5rem;

        h1 {
            color: $primaryColor;
            font-weight: 500;
        }

        .btn {
            padding: 0.5rem 1rem;
            justify-self: end;
            width: fit-content;
        }

        .input-wrapper {
            .reasons {
                display: grid;

                .reason {
                    padding: .5rem 0;
                    padding-right: 1rem;
                    width: 100%;
                    padding-left: .3rem;
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    border: .1rem solid lighten($color: grey, $amount: 20);
                    border-radius: .3rem;
                    margin-bottom: 0.5rem;
                    font-weight: 500;
                    color: $primaryColor;
                    cursor: pointer;
                    transition: .25s;

                    &:hover,
                    &.selected {
                        border: .1rem solid $primaryColor;

                        .circle {
                            border: 1px solid $primaryColor;
                        }
                    }

                    &.selected {
                        .circle {
                            &::after {
                                transform: translate(-45%, -55%) scale(100%);
                            }
                        }
                    }

                    .circle {
                        width: 1.2rem;
                        height: 1.2rem;
                        margin-right: .7rem;
                        border: 1px solid grey;
                        border-radius: 50%;
                        position: relative;

                        &::after {
                            content: "";
                            position: absolute;
                            width: .8rem;
                            height: .8rem;
                            transition: .25s;
                            background: $primaryColor;
                            border-radius: 50%;
                            top: 50%;
                            left: 50%;
                            transform: translateY(-50%) scale(0);
                        }
                    }

                }
            }

            small {
                font-size: 0.7rem;
            }

            label {
                text-transform: uppercase;
                font-size: .9rem;
                color: $primaryColor;
                font-style: italic;
                margin-bottom: 1rem;
            }

            input {
                width: 100%;
                height: 2.5rem;
                border: .1rem solid lighten($color: grey, $amount: 20);
                border-radius: .3rem;
                overflow: hidden;
                font-size: 1rem;
                padding-left: 0.3rem;
                color: $primaryColor;

                &:focus {
                    outline: 1px solid $primaryColor;
                }
            }
        }
    }
}

.modal-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #0005;
    display: grid;
    place-items: center;
    left: 0;
    top: 0;

    .modal {
        width: 95%;
        max-width: 27rem;
        background: #fff;
        border-radius: .5rem;

        .modal-header,
        .modal-body,
        .modal-footer {
            padding: .8rem 1.5rem;
        }

        .modal-header,
        .modal-body {
            border-bottom: .1rem solid #8080808a;
        }

        .modal-header {
            font-size: 1.2rem;
            font-weight: 500;
            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
                font-size: 1.2rem;
                padding: 0;
                border: none;
                background: none;
                cursor: pointer;
            }
        }

        .modal-footer {
            display: flex;
            align-items: center;
            justify-content: end;

            .btn {
                padding: 0.5rem 1rem;

                &.btn-cancel {
                    margin-right: 0.5rem;
                    background: none;
                    border: 1px solid $primaryColor;
                    color: $primaryColor;
                }
            }
        }
    }
}