.services {
  width: 100%;
  height: 100%;
  padding: 1rem 5rem;
  margin-bottom: -7rem;
  @media screen and (max-width: 991px) {
    padding: 1rem;
  }
  .headingTitle {
    font-size: 1.5rem;
    text-align: center;
    color: $primaryColor;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  .servSec {
    position: relative;
    width: 100%;
    height: 100vh;
    background-image: url("../../../public/L-page_assets/Rectangle_85.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    place-items: center;
    .bgb {
      background: #0000009a;
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .cont {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      text-align: center;
      padding: 8rem;
      color: $secondaryColor;
      position: absolute;
      z-index: 1000;
      @media screen and (max-width: 991px) {
        padding: 5rem 2rem;
      }
      .hed {
        font-size: clamp(1.8rem, 3vw, 2.5rem);
        font-weight: 600px;
      }
      .cnt {
        font-size: clamp(0.8rem, 3vw, 1.1rem);
        font-weight: 600px;
      }
    }
  }
  .discover_bv {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    margin-top: 4rem;
    @media screen and (max-width: 991px){
      margin-bottom: -5rem;
    }
    h3 {
      font-size: clamp(1.5rem, 3vw, 1.7rem);
      font-weight: 600;
      color: $primaryColor;
    }
    .card-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 4rem;
      justify-content: center;
      margin-bottom: .5rem;
      .card {
        width: 100%;
        max-width: 320px;
        height: 400px;
        position: relative;
        img {
          width: 150px;
        }
        h3 {
          font-size: clamp(1.5rem, 3vw, 1.7rem);
          font-weight: 600;
          line-height: 32px;
        }
        p {
          font-size: clamp(0.9rem, 3vw, 1rem);
          font-weight: 400;
          line-height: 27px;
        }
        .line {
          width: 100%;
          height: 7px;
          background: $primaryColor;
          position: absolute;
          border-radius: 1rem;
          bottom: 0;
        }
      }
    }
  }
  .blob {
    width: 100%;
    height: 380px;
    background-image: url("../partials/../../../public/L-page_assets/vector.png");
    display: grid;
    place-items: center;
    padding: 13rem 4rem 0 4rem;
    p {
      width: 100%;
      text-align: center;
      font-size: clamp(0.8rem, 2vw, 1.5rem);
      font-weight: 500;
    }
  }
  .sliderDiv {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    margin-top: 8rem;
    box-shadow: 0 0 0.6rem 0.3rem #00000043;
    background: #0000000c;
    padding: 0 1rem 1rem 1rem;
    margin-bottom: -5rem;
    @media screen and (max-width: 905px){
      margin-bottom: -15rem;         
    }
    @media screen and (max-width: 483px){
      margin-bottom: -25rem;         
    }
    .headings {
      margin-top: -4rem;
      img {
        width: 100px;
        border: 1px solid black;
        border-radius: 50%;
      }
      .line {
        width: 100px;
        height: 5px;
        background: $primaryColor;
        border-radius: 1rem;
      }
    }
    h3 {
      font-size: clamp(1.5rem, 3vw, 2.5rem);
      font-weight: 600;
      color: $primaryColor;
    }
    p {
      width: 70%;
      text-align: center;
    }
    .sliderWrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      align-items: center;
      justify-content: center;
      padding-bottom: 5rem;
      .image {
        width: 48%;
        padding: .5rem;
        height: 29rem;
        position: relative;
        &:hover{
          .over{
            transform: scaleY(1);
          }
        }
        max-width: 23rem;
        @media screen and (max-width: 371px){
          width: 250px;
        }
        .over{
          position: absolute;
          width: 100%;
          height: 12%;
          bottom: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #000000b4;
          border-radius: 0 0 .5rem .5rem;
          gap: 1rem;
          transition: .3s;
          transform: scaleY(0);
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: .5rem;
        }
        background: white;
        padding: 0.5rem;
        border-radius: 1rem;
        .foot {
          padding: 0.5rem;
          p {
            width: 100%;
            display: flex;
            align-self: start;
          }
          .name {
            font-size: 1.3rem;
            font-weight: 600;
          }
          .pos {
            opacity: 0.5;
          }
        }
      }
    }
    .view {
      font-size: 1rem;
      padding: 0.5rem 1rem;
      background: $primaryColor;
      color: $secondaryColor;
      border: none;
      margin-top: 3rem;
      border-radius: 0.5rem;
      a {
        color: white;
      }
    }
  }
  .discover_bv {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    margin-top: 4rem;
    h3 {
      font-size: 36px;
      font-weight: 600;
      color: $primaryColor;
    }
    .card-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 4rem;
      justify-content: center;
      .card {
        width: 100%;
        max-width: 407px;
        height: 500px;
        position: relative;
        img {
          width: 200px;
        }
        h3 {
          font-size: 32px;
          font-weight: 600;
          line-height: 48px;
        }
        p {
          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
        }
        .line {
          width: 100%;
          height: 7px;
          background: $primaryColor;
          position: absolute;
          border-radius: 1rem;
          bottom: 2.8rem;
        }
      }
    }
  }
  .blob{
    width: 100%;
    height: 471px;
    background-image: url('../partials/../../../public/L-page_assets/vector.png');
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 7rem;
    p{
      width: 100%;      
      text-align: center;
      font-size: clamp(.9rem, 2vw, 2rem);
      font-weight: 500;
      margin-top: 8rem;
      @media screen and (max-width: 560px) {
        margin-top: 0;
      }
    }
  }
  .sliderDiv{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
    .headings{
      img{
        width: 70px;
        border: 1px solid black;
        border-radius: 50%;
      }
      .line{
        width: 50px;
        height: 5px;
        background: $primaryColor;
      }
    }
    p{
      width: 70%;
      text-align: center;
    }
    .sliderWrapper{
      display: flex;
      gap: 1rem;
      align-items: center;
    }
  }
}
