.aboutUs {
  width: 100%;
  height: 100%;
  padding: 1rem 5rem;
  margin-bottom: -2rem; 
  @media screen and (max-width: 991px) {
    padding: 1rem 4rem;
  }
  @media screen and (max-width: 760px) {
    padding: 1rem;
  }
  .headingTitle {
    font-size: 1.5rem;
    text-align: center;
    color: $primaryColor;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  .abtSec {
    position: relative;
    width: 100%;
    height: 100vh;
    background-image: url("../../../public/L-page_assets/Rectangle_59.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    place-items: center;
    .bgb {
      background: #0000009a;
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .abtCnt {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      text-align: center;
      padding: 8rem;
      color: $secondaryColor;
      position: absolute;
      z-index: 1000;
      @media screen and (max-width: 991px) {
        padding: 5rem 2rem;
      }
      .wlc {
        font-size: clamp(1.8rem, 3vw, 2.5rem);
        //   font-weight: 600;
      }
      .jrn {
        font-size: clamp(0.8rem, 3vw, 1.1rem);  
        font-weight: bold;
      }
    }
  }
  .wrapper {
    margin-top: 4rem;
    margin-bottom: 4rem;
    .resp {
      flex-direction: column;
      display: none;
      @media screen and (max-width: 991px) {
        display: flex;
      }
      .heading {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        h3 {
          font-size: 1.5rem;
          text-align: left;
          color: $primaryColor;
        }
        .ttl {
          text-align: right;
        }
        .line {
          width: 150px;
          height: 5px;
          background: #ff0000;
          border-radius: 1rem;
          box-shadow: 0 0.2rem 0.5rem #00000094;
        }
        .dsh{
          display: flex;
          align-self: end;
        }
      }
      p {
        color: $primaryColor;
      }
    }
    .wrapp {
      @media screen and (max-width: 991px) {
        display: none;
      }
      .heading {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        h3 {
          font-size: 1.5rem;
          text-align: left;
          color: $primaryColor;
          position: relative;
          width: 10rem;
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 5px;
            background: #ff0000;
            border-radius: 1rem;
            box-shadow: 0 0.2rem 0.5rem #00000094;
            bottom: -1rem;
            left: 0;            
          }
        }
        .ttl {
          display: flex;
          align-self: end;
          text-align: right;
          padding-left: .8rem;
        }
        .line {
          width: 150px;
          height: 5px;
          background: #ff0000;
          border-radius: 1rem;
          box-shadow: 0 0.2rem 0.5rem #00000094;
        }
        .dash {
          margin-left: 0;
        }
      }
      .par {
        width: 100%;
        height: 100%;
        display: flex;
        .mckup {
        }
        .rightImg {
          background-image: url("../partials/../../../public/L-page_assets/rectangle_60.png");
          width: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          display: grid;
          place-items: center;
          position: relative;
          .bgb {
            background: #ff000048;
            width: 100%;
            height: 100%;
            position: absolute;
          }
          .cont {
            background: #220942;
            position: absolute;
            color: white;
            margin: auto;
            width: 90%;
            padding: 0.3rem 1rem;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            border-radius: 1rem;
            img {
              width: 50px;
              color: white;
              @media screen and (max-width: 991px) {
                display: none;
              }
            }
            div {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .globe {
              }
            }
            p{
              font-size: .9rem;
            }
          }
        }
      }
    }
  }
  .sec3 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: -12rem;
    @media screen and (max-width: 991px) {
      padding: 1rem;
      margin-bottom: -25rem;
    }
    @media screen and (max-width: 820px) {
      padding: 1rem;
      margin-bottom: -32rem;
    }
    @media screen and (max-width: 500px) {
      padding: 1rem;
      margin-bottom: -37rem;
    }
    .wrap {
      display: flex;
      align-items: center;
      gap: 4rem;
      justify-content: space-between;
      margin-bottom: -2rem;
      @media screen and (max-width: 991px) {
        flex-direction: column;
        margin-bottom: 1rem;
        align-self: start;
      }
      .part1 {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        max-width: 600px;
        color: $primaryColor;
        font-weight: 500;
        h3 {
          font-size: clamp(1.5rem, 3vw, 2rem);
        }
        ol {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          li {
            .head {
              color: #ff0000;
            }
          }
        }
      }
      .part2 {
        width: 100%;
        height: 100vh;
        background-size: cover;
        position: relative;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 991px) {
          display: none;
          height: 0;
        }
        img {
          width: 100%;
        }
        button {
          display: flex;
          margin-left: 10rem;
          align-self: center;
          padding-block: 0.5rem;
          @media screen and (max-width: 991px) {
            display: none;
          }
        }
        .shp {
          margin-left: 1rem;
          border: 1px solid;
        }
      }
    }
  }
}
